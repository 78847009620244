<template>
  <div class="guidance-show">
    <!-- Loading -->
    <div v-if="isLoading" class="text-center loading-wrapper">
      <a-spin />
    </div>
    <!-- / Loading -->

    <!-- No guidance pack -->
    <div
      v-if="!isLoading && !guidancePackContents"
      class="no-guidance-pack-wrapper"
    >
      <a-alert
        type="error"
        message="Error loading guidance pack contents, please try again."
      />
    </div>
    <!-- / No guidance pack -->

    <!-- Guidance pack contents loaded -->
    <div
      v-if="!isLoading && guidancePackContents && guidancePack"
      class="guidance-pack-inner"
    >
      <!-- Top -->
      <div class="guidance-pack-inner-top">
        <!-- Header -->
        <div class="guidance-pack-header">
          <div class="left">
            <a-button
              v-if="!(selectedTopic || selectedParent || selectedContentType)"
              type="default"
              style="display: inline; margin-right: 15px"
              @click.prevent="backToAllGuidancePacks"
              icon="left"
            ></a-button>
            <organisation-thumbnail
              style="display: inline"
              :tenant-id="tenantId"
              :org-id="guidancePackId"
            ></organisation-thumbnail>
          </div>
          <div class="right">
            <div class="page-header" style="margin-bottom: 10px !important">
              <h1 class="page-title">
                {{ guidancePack["$v"]["displayName"] }}
              </h1>
            </div>
          </div>
        </div>
        <!-- / Header -->
      </div>
      <!-- / Top -->

      <!-- Bottom -->
      <div class="guidance-pack-inner-bottom">
        <!-- No topic or parent selected selected -->
        <div
          v-if="
            !(
              (selectedTopic && !selectedTopicShouldBeDisplayedInModal) ||
              selectedParent
            )
          "
        >
          <!-- No content type selected -->
          <div v-if="!selectedContentType">
            <a-row type="flex" :gutter="20">
              <a-col
                class="guidance-pack-column"
                :span="12"
                v-for="topic in topicsSorted"
                :key="topic['$v']['id']"
              >
                <guidance-pack-topic-card
                  @selected="() => handleParentSelected(topic)"
                  :topic="topic"
                ></guidance-pack-topic-card>
              </a-col>
            </a-row>

            <content-type-groups></content-type-groups>
          </div>
          <!-- / No content type selected -->

          <!-- Content type selected -->
          <div v-if="selectedContentType">
            <content-type-page></content-type-page>
          </div>
          <!-- / Content type selected -->
        </div>
        <!-- / No topic or parent selected -->

        <!-- Parent Selected And No Topic Selected -->
        <div
          v-if="
            selectedParent &&
            !(selectedTopic && !selectedTopicShouldBeDisplayedInModal)
          "
        >
          <parent-children-page></parent-children-page>
        </div>
        <!-- Parent Selected And No Topic Selected -->

        <!-- Topic selected - not modal view -->
        <topic-tabs
          v-if="selectedTopic && !selectedTopicShouldBeDisplayedInModal"
        ></topic-tabs>
        <!-- / Topic selected - not modal view -->

        <!-- Topic selected - modal view -->
        <topic-modal
          v-if="selectedTopic && selectedTopicShouldBeDisplayedInModal"
        ></topic-modal>
        <!-- / Topic selected - modal view -->
      </div>
      <!-- / Bottom -->
    </div>
    <!-- / Guidance pack contents loaded -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import OrganisationThumbnail from "../../components/Organisations/OrganisationThumbnail.vue";
import GuidancePackTopicCard from "./Show/GuidancePackTopicCard.vue";
import TopicTabs from "./Show/TopicTabs.vue";
import TopicModal from "./Show/TopicModal.vue";
import ContentTypeGroups from "./Show/ContentTypeGroups.vue";
import ContentTypePage from "./Show/ContentTypePage.vue";
import ParentChildrenPage from "./Show/ParentChildrenPage.vue";
const _ = require("lodash");
export default {
  name: "Show",
  components: {
    OrganisationThumbnail,
    GuidancePackTopicCard,
    TopicTabs,
    TopicModal,
    ContentTypeGroups,
    ContentTypePage,
    ParentChildrenPage,
  },
  computed: {
    ...mapGetters("guidancePackViewer", {
      guidancePack: "guidancePack",
      guidancePackContents: "guidancePackContents",
      isLoading: "isLoading",
      topics: "topics",
      selectedTopic: "selectedTopic",
      selectedTopicShouldBeDisplayedInModal:
        "selectedTopicShouldBeDisplayedInModal",
      selectedContentType: "selectedContentType",
      selectedParent: "selectedParent",
    }),

    tenantId() {
      return this.$route.params.tenantId;
    },

    guidancePackId() {
      return this.$route.params.id;
    },

    topicsSorted() {
      return _.orderBy(
        this.topics,
        ["$v.rank", "$v.displayName"],
        ["desc", "asc"]
      );
    },
  },
  methods: {
    ...mapActions("guidancePackViewer", {
      loadGuidancePack: "loadGuidancePack",
      selectTopic: "selectTopic",
      selectParent: "selectParent",
    }),

    ...mapActions("guidance", {
      loadLastViewsIfNotLoaded: 'loadLastViewsIfNotLoaded'
    }),

    backToAllGuidancePacks() {
      this.$router.push("/guidance");
    },

    handleParentSelected(parent) {
      this.selectParent(parent["$v"]["id"]);
    },

    handleTopicSelected(topic) {
      if (topic["$t"] == "Soteria.Models.ExternalLinkDetails, Soteria.Models") {
        window.open(topic["$v"]["url"], "_blank");
      } else {
        this.selectTopic(topic["$v"]["id"]);
      }
    },
  },
  created() {
    let idFromUrl = this.$route.params.id;
    this.loadGuidancePack({
      tenantId: this.tenantId,
      id: idFromUrl,
    });
    this.loadLastViewsIfNotLoaded();
  },
};
</script>

<style scoped lang="scss">
.loading-wrapper {
  margin-top: 50px;
}

.guidance-pack-inner,
.guidance-show {
  height: 100%;
}

.guidance-pack-inner {
  display: flex;
  flex-direction: column;
  .guidance-pack-inner-top {
    flex-shrink: 1;
  }
  .guidance-pack-inner-bottom {
    flex-grow: 1;
  }
}

.guidance-pack-header {
  display: flex;

  .left {
    flex-shrink: 1;
    padding-right: 20px;
  }

  .right {
    flex-grow: 1;
  }
}

.no-guidance-pack-wrapper {
  margin-top: 50px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.guidance-pack-column {
  margin-bottom: 20px;
}

.guidance-pack-topic-card {
  min-height: 130px;
  height: 100%;
}

.content-type-groups {
  padding-top: 20px;
  padding-bottom: 10px;
}
</style>